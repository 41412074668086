import LocalizedStrings from "react-localization"

export default new LocalizedStrings({
	/* cSpell:enable */
	/* cSpell.language:pt,pt_PT */
	pt: {
		// Generic
		generic: {
			askToSave: "Deseja guardar as alterações antes de sair da página?",
			edit: "Editar",
			done: "Concluir",
			accept: "Aceitar",
			cancel: "Cancelar",
			remove: "Remover",
			forget: "Esquecer User",
			delete: "Eliminar",
			success: "Sucesso",
			active: "Ativar",
			expand: "Expandir",
			toggle: "Toggle",
			save: "Guardar",
			yes: "Sim",
			no: "Não",
			apply: "Aplicar",
			item: "Artigo",
			items: "Artigos",
			submit: "Submeter",
			continue: "Continuar",
			confirm: "Confirmar",
			finish: "Terminar",
			back: "Voltar",
			oops: "Oops",
			error: "Erro",
			retry: "Tentar novamente",
			reset: "Reset",
			search: "Pesquisar",
			noResults: "Sem Resultados",
			clickHereTo: "Clique para {0}",
			requiredField: "{0} é um campo obrigatório",
			duplicateEmail: "Este email já está a ser utilizado. Por favor utilize outro email.",
			emptyList: "Sem {0} na lista",
			leavePage: "Sair da Página?",
			leave: "Sair",
			stay: "Ficar",
			open: "Abrir",
			close: "Fechar",
			change: "Mudar",
			free: "Grátis",
			send: "Enviar",
			selected: "Selecionado",
			selecteds: "Selecionados",
			swalEditTitle: "Cancelar Alteração?",
			swalEditDescription: "Cancelar edição vai apagar toda a informação não guardada",
			leaveDescription: "Ao sair desta página vai apagar toda a informação não guardada",
			swalDeleteTitle: "Apagar {0}?",
			swalDeleteDescription: "Ao sair desta página vai apagar todos os {0} na sua lista",
			genericSelect: "Selecionar {0}",
			select: "Selecionar",
			addGeneric: "Adicionar {0}",
			moreOptions: "Mais Opções",
			all: "Tudo",
			enable: "Ativar",
			newF: "Nova",
			newM: "Novo",
			addNewF: "Adicionar nova {0}",
			addNewM: "Adicionar novo {0}",
			default: "Pré-Definido",
			editGeneric: "Editar {0}",
			infinite: "Infinito",
			details: "Detalhes",
			noItemsSelected: "Nenhum Item Selecionado",
			clearList: "Limpar lista",
			loading: "A Carregar...",
			view: "Ver",
			actions: "Ações",
			invalidUrl: "URL inválido. Por favor remova o prefixo HTTP.",
			shop: "Loja",
			noEntries: "Sem Valores",
			chatWalktroughText1:
				"Adicionámos diversas FAQ que podem ajudar a conhecer e entender como funciona o produto Shop2Grow. Essas FAQ estão disponíveis no ícone de chat no canto inferior direito do ecrã.",
			chatWalktroughText2:
				"Estamos também disponíveis, através de chat, para ajudar na melhor maneira de criar e definir a sua loja.",
			skip: "Saltar Walkthrough",
			walkTrough: "Walkthrough",
			clone: "Clonar",
			download: "Download",
			upload: "Upload",
			template: "Download Template",
			export: "Exportar",
			import: "Importar",
			defaultItem: "um item",
			defaultItemRecipient: "este/esta {0}",
			demo: "Demo",
			previewOnly: "Apenas Preview",
			selectPaymentMethod:
				"Por favor, selecione um método de pagamento antes de atualizar a sua subscrição.",
			basic: "Básico",
			advanced: "Avançado",
			editMode: "Modo de Edição",
			print: "Imprimir",
			code: "Codigo",
			filters: "Filtros",
			changeImage: "Clique para adicionar uma imagem ou arraste o ficheiro para esta caixa.",
			changeFile: "Clique para adicionar um ficheiro ou arraste-o para esta caixa.",
			userImage: `Este utilizador ainda não tem imagem`,
			changeVideo:
				"Clique para adicionar um ficheiro de video ou arraste o ficheiro para esta caixa.",
			changeYoutubeVideo: "Insira um link de youtube",
			changeAudio:
				"Clique para adicionar um ficheiro de áudio ou arraste o ficheiro para esta caixa.",
			everyF: "Todas",
			everyM: "Todos",
			generalInformation: "Informação Geral",
			sync: "Sincronizar"
		},

		fields: {
			name: "Nome",
			externalName: "Nome Externo",
			address: "Morada",
			addresses: "Moradas",
			country: "País",
			state: "Estado",
			city: "Cidade",
			zipCode: "Código Postal",
			description: "Descrição",
			phone: "Telefone",
			phoneNumber: "Número de Telefone",
			phoneShort: "Tel.:",
			mobilePhone: "Telemóvel",
			email: "Email",
			seoUrl: "SEO Url",
			tags: "Tags",
			ccNumber: "Número do CC",
			billingAddress: "Morada de Faturação",
			shippingAddress: "Morada de Envio",
			vat: "VAT",
			password: "Password",
			message: "Mensagem",
			image: "Imagem",
			images: "Imagens",
			imgPlaceholder: "Tente arrastar imagens para aqui, ou clique para fazer upload",
			filesPlaceholder:
				"Tente arrastar o ficheiro para aqui ou clique para selecionar o ficheiro",
			subTotal: "Subtotal",
			total: "Total",
			quantity: "Quantidade",
			ordered: "Qtd. Encomendada",
			totalQuantity: "Quantidade Total",
			discount: "Desconto",
			registerDate: "Data Registo",
			invalidEmail: "Email não é valido",
			tax: "Taxa",
			addedOn: "Adicionado",
			startDate: "Data Inicial",
			endDate: "Data Final",
			startHour: "Hora Inicial",
			endHour: "Hora Final",
			filename: "Nome do Ficheiro",
			gender: "Género",
			waitingForResults: "À espera do nome ou SKU...",
			link: "Link",
			date: "Data",
			otherRegions: "Outras Zonas",
			taxValues: "Taxas",
			previewLink: "Link de Pré-Visualização",
			clickToUpload: "Clique para fazer o Upload",
			photo: "Foto",
			shift: "Turno",
			shiftEnd: "Fim de Turno",
			shiftStart: "Início de Turno",
			contractTypeName: "Nome do Contrato",
			contractTypeUrl: "Url optimizador",
			isActive: "Ativo",

			// Select
			selectCountry: "Selecionar País",
			selectState: "Selecionar Estado",
			selectFilters: "Selecionar Filtros",
			selectDiscount: "Selecionar Desconto",

			countries: "Países",
			states: "Estados",

			// Pairs
			valuePair: "{0} / {1}",
			placeHolderPair: "{0} => {1}",

			code: "Abreviatura",
			bccEmail: "Email para receber encomendas",
			article: "Artigo",

			logo: "Logo",
			category: "Categoria",
			weight: "Peso"
		},

		language: {
			header: "Idioma",
			defaultLanguage: "Idioma Padrão",
			languageNotActive:
				"Guarde as atualizações antes de proceder à alteração do idioma padrão.",
			portuguese: "Português",
			english: "Inglês",
			german: "Alemão",
			french: "Francês",
			spanish: "Espanhol"
		},

		sidebar: {
			dashboard: "Dashboard",
			profile: "Perfil",
			information: "Informação",
			staff: "Staff",
			settings: "Definições",
			logs: "Logs",
			users: "Utilizadores",
			businesses: "Clientes",
			orders: "Encomendas"
		},

		header: {
			account: "Conta"
		},

		// Screens
		authentication: {
			welcome: "Bem-vindo",
			header: "Autenticação",
			register: "Registo",
			login: "Entrar",
			logout: "Sair",
			signIn: "Autenticar",
			confirmAccount: "Confirmar Conta",
			name: "Nome",
			namePlaceholder: "O seu nome",
			email: "Email",
			emailPlaceholder: "O seu email",
			password: "Password",
			passwordPlaceholder: "A sua password",
			newPassword: "Nova password",
			newPasswordPlaceholder: "Inserir nova password",
			confirmPassword: "Confirmar Password",
			confirmPasswordPlaceholder: "Repetir a password",
			forgotPassword: "Esqueceu-se da password?",
			loginFooter: "Não tem conta? Registe-se aqui",
			registerFooter: "Já tem conta? Autentique-se aqui.",
			resendCode1: "Não recebeu o seu código de confirmação? ",
			resendCode2: "Reenviar código de ativação",
			sendEmail: "Enviar Email",
			recoverPassword: "Recuperar password",
			changePassword: "Mudar Password",
			changeEmail: "Mudar Email",
			newEmail: "Novo Email",
			newEmailConfirm: "Confirmar Novo Email",
			repeatEmail: "Repetir Email",
			currentEmail: "Email Atual",
			currentPassword: "Password Atual",
			emailSent: "Email Enviado com Sucesso",
			repeatPassword: "Repetir Password",
			rememberedPassword: "Lembrou-se da password?",
			adminPassword: "Password do Administrador",
			notLoggedIn: "Necessita de estar autenticado para ter acesso a esta página",
			noMatchPassword: "As passwords não coincidem",
			acceptSubtitle: "Por favor insira uma password para confirmar a sua conta.",
			confirmInvite: "Por favor insira a sua password para aceitar o convite.",
			goBackToLogin: "Ir para o ecrã de início de sessão",
			resendInviteEmail: "Reenviar email de convite",
			confirmResendInviteEmail: "Reenviar email de convite para {0}?",
			forgetTitle:
				"Tem a certeza que deseja esquecer o utilizador {0}? Esta ação irá deixar o utilizar sem acesso à app, e todos os dados serão removidos.",
			forgetConfirm: "Esquecer Utilizador",
			backoffice: "Backoffice"
		},

		acceptInvite: {
			header: "Aceitar Convite",
			title: "Olá {0}, {1} convidou-o(a) a pertencer a {2}.",
			title2: "Olá {0}, {1} convidou-o(a) para gerir {2}.",
			subTitle: "Insira uma password para confirmar a sua conta",
			expired: "Este convite expirou"
		},

		notFound: {
			header: "Página Inexistente"
		},

		importModal: {
			fileName: "Nome do Ficheiro",
			size: "Tamanho",
			import: "Importar"
		},

		errors: {
			invalidArgs: "Argumentos inválidos ou em falta",
			invalidFields: "Campos inválidos ou em falta",
			emailIsInvalid: "O formato de email inserido não é válido",
			fillEmail: "Insira um email por favor",
			fillPassword: "Insira uma password por favor",
			pleaseFillFormCorrectly: "Por favor preencha o formulário correctamente",
			fillField: "Este campo é de preenchimento obrigatório",
			notSupportedFile: "Tipo de ficheiro não suportado",
			nameIsEmpty: "O campo nome é de preenchimento obrigatório",
			passwordsDontMatch: "Os campos 'Nova password' e 'Confirmar password' não coincidem",
			emailIsEmpty: "O campo email é de preenchimento obrigatório",
			fileTooBigorInvalid: "O tamanho da imagem é superior a 8MB ou tem um formato inválido",
			unsupportedFile: "Ficheiro não suportado",
			fileNotSupported: "O ficheiro que tentou carregar, não é suportado.",
			processingError: "Erro de Processamento",
			imageProcessingError: "{images} não puderam ser processadas.",
			shiftDateError:
				"A hora de início de turno tem de ser uma hora anterior à hora de final de turno."
		},

		profile: {
			profilePicture: "Foto de Perfil",
			role: "Cargo",
			editedSuccessfully: "Perfil editado com sucesso",
			recurrence: "Recorrência",
			recurrenceActive: "Ativar Recorrência",
			recurrenceUntil: "Recorrente até",
			recurringEndMissing: "A data final de recorrência é obrigatória.",
			recurrenceSuccess: "Definições de recorrência guardadas com sucesso.",
			deleteRecurrence: "Eliminar recorrência",
			noThankYou: "Não Obrigado(a)",
			recurrenceTooltip: "Criar recorrência",
			deleteRecurrenceDescription: "Pretende eliminar a recorrência do dia {date}?"
		},

		placeholders: {
			name: "O seu nome",
			email: "o_seu@email.com",
			phoneNumber: "+351265000000",
			language: "Selecione uma língua",
			role: "Selecione um cargo",
			currentPassword: "password actual",
			newPassword: "nova password",
			confirmPassword: "nova password",
			newEmail: "novo@email.com",
			page: "pagina",
			titlePage: "Título da Página",
			link: "https://www.google.com/"
		},

		staff: {
			owner: "Dono",
			owners: "Donos",
			admin: "Administrador",
			marketing: "Marketing",
			editedSuccessfully: "Utilizador editado com sucesso",
			passwordChangedSuccessfully: "Password alterada com sucesso",
			emailChangedSuccessfully: "Email alterado com sucesso",
			wrongPassword: "Password Errada",
			emailInUse: "Email em uso",
			status: "Estado",
			confirmed: "Confirmado",
			pending: "Pendente",
			createStaff: "Criar Utilizador",
			createdSuccessfully: "Utilizador criado com sucesso.",
			sysAdmin: "System Admin",
			addStaff: "Adicionar Utilizador",
			editStaff: "Editar Utilizador",
			addOwner: "Adicionar Dono",
			editOwner: "Editar Dono",
			user: "Utilizador",
			archiveStaff: "Arquivar Utilizador",
			removed: "Utilizador foi removido com sucesso.",
			disableStaff: "Desativar um Utilizador",
			enableStaff: "Ativar Utilizador",
			disabled: "Utilizador desativado com sucesso.",
			enabled: "Utilizador ativado com sucesso.",
			manager: "Gestor",
			supervisor: "Supervisor",
			dispatcher: "Expedidor",
			driver: "Motorista",
			operator: "Operador"
		},

		ranges: {
			today: "Hoje",
			thisMonth: "Este mês",
			lastMonth: "Último mês",
			thisYear: "Este ano",
			lastYear: "Último ano"
		},

		dashboard: {
			period: "Período",
			active: "Ativo",
			homepage: "Homepage",
			inStock: "Em Stock",
			daily: "Diário",
			monthly: "Mensal",
			newUsers: "Novos Utilizadores",
			newUsersContent: "Novos Utilizadores Conteúdo",
			uses: "Utilizações",
			androidUse: "Utilização Android",
			androidUseContent: "Utilização Android Conteúdo",
			iOSUse: "Utilização iOS",
			iOSUseContent: "Utilização iOS Conteúdo",
			webUse: "Utilização Web",
			webUseContent: "Utilização Web Conteúdo",
			contract: "Contrato",
			sosButton: "Botão de Chamada",
			chatButton: "Botão de Chat",
			usage: "Utilizadores",
			videoAppointments: "Vídeo-consultas de Psicologia",
			hotline: "Linhas de Apoio Psicológico",
			disturbances: "Perturbações Diagnosticadas",
			contractDetails: "Detalhe Contrato",
			purchased: "Contratadas",
			consumed: "Consumidas",
			balance: "Balanço",
			scheduled: "Consultas Marcadas",
			finished: "Consultas Realizadas",
			details: "Ver Detalhe",
			clicked: "N.º Vezes Clicado",
			usageApp: "Utilização App",
			calls: "Chamadas",
			chats: "Chats",
			records: "Registos de Intervenções",
			noData: "Não existe data registada no período selecionado.",
			selectPsychologist: "Selecione um(a) psicólogo(a)",
			question1: "De forma geral, está satisfeito(a) com o serviço prestado?",
			question2: "Em que medida gostou do(a) psicólogo(a) que o(a) atendeu?",
			question3: "O(a) psicólogo(a) foi pontual no seu atendimento?",
			question4: "Sente-se melhor depois da intervenção psicológica realizada?",
			question5: "No seu entender, o número de consultas está a foi adequado?",
			question6: "Em caso de necessidade, voltaria a utilizar o serviço?",
			question7: "Quanto recomendaria este serviço a um colega?",
			question8: "Considera que esta intervenção ajudou que se sentisse mais motivado?",
			question9:
				"Com esta intervenção, tornou-se mais fácil gerir o equilíbrio entre a vida familiar e o trabalho?",
			question10:
				"Considera importante manter o serviço de apoio psicológico na sua organização?",
			notes: "Caso queira deixar alguma sugestão, por favor, escreva abaixo",
			reviewHelp:
				'De {0} a {1}, em que {2} é "{3}" e {4} corresponde a "{5}", por favor, classifique as seguintes afirmações relativas às vídeo-consultas de Psicologia realizadas com os psicólogos da TEAM 24.',
			notReally: "muito pouco",
			little: "pouco",
			few: "moderadamente",
			many: "muito",
			alot: "extremamente"
		},

		settings: {
			pages: "Páginas",
			pagesDescription:
				"Nesta área poderá alterar o conteudo das páginas Termos & Condições e Politica de Privacidade",
			emailTemplates: "Templates de E-mails",
			emailTemplatesDescription:
				"Parametrização dos templates de emails, enviados aos utilizadores, assim como os utilizados pelo staff do Backoffice",
			vehicles: "Veículos",
			vehiclesDescription: "Registe a frota responsável existente e as suas características",
			addVehicle: "Adicionar veículo",
			editVehicle: "Editar veículo",
			added: "O veículo foi adicionado com sucesso.",
			edited: "O veículo foi editado com sucesso.",
			deleted: "O veículo foi eliminado com sucesso.",
			licensePlate: "Matrícula",
			licensePlateRequired: "A matrícula é um campo de preenchimento obrigatório.",
			nameRequired: "O nome é um campo de preenchimento obrigatório.",
			typeRequired: "O tipo é um campo de preenchimento obrigatório.",
			capacityRequired: "A capacidade é um campo de preenchimento obrigatório.",
			vehiclePlate: "Matrícula do veículo",
			vehicleType: "Tipo de veículo",
			contractType: "Tipo de Contrato",
			capacity: "Capacidade",
			van: "Carrinha",
			bike: "Mota",
			boxes: "Caixas",
			grids: "Grade",
			gridsDescription: "Registe uma grade para ser preenchida nas rotas",
			shifts: "Turno",
			shiftsDescription: "Registe um turno para ser usado nas encomendas",
			addGrid: "Adicionar Grade",
			editGrid: "Editar Grade",
			addShift: "Adicionar Turno",
			editshift: "Editar Turno",
			contractTypes: "Tipos de contracto",
			contractTypesDescription: "Registe um tipo de contracto.",
			food: "Alimentar",
			parcel: "Parcel",
			pharmacy: "Farmácia",
			contractUrl: "Url optimizador",
			editContractType: "Editar Tipo de Contracto",
			addContractType: "Adicionar Tipo de Contracto",
			uploadMasterData: "Importar Master Data",
			uploadMasterDataSubtitle:
				"Importe o ficheiro de Master Data (contratos de farmácia), para importar clientes no sistema",
			userAlreadyExists: "Já existe um cliente com esse id",
			importedWithErrors:
				"Ficheiro Master Data importado com sucesso. Alguns clientes não foram importados (ver lista de erros).",
			imported: "O ficheiro Master Data foi importado com sucesso."
		},

		pages: {
			single: "Página",
			title: "Título",
			content: "Conteúdo",
			new: "Nova Página",
			isLink: "Redirecionamento"
		},

		templates: {
			single: "Template"
		},

		emails: {
			single: "E-mail",
			tags: "Tags",
			preview: "Pré-visualização",
			subject: "Assunto",
			cc: "CC",
			templates: "Templates de E-mail",
			invite: "Convidar Staff",
			edited: "Template de E-mail editado com sucesso.",
			plainText: "Plain text"
		},

		serverErrors: {
			title: "Erro de Servidor",
			subTitle: "Não é você, somos nós!",
			refresh: "Voltar à Página Anterior",
			backToDashboard: "Ir para o Dashboard",
			wentWrong: "Algo correu mal."
		},

		logs: {
			dateRange: "Date Range",
			userId: "User Id",
			url: "Url",
			token: "Token",
			codes: "Codes",
			methods: "Methods",
			sources: "Sources",
			types: "Types"
		},

		emailTemplates: {
			generalEmail: "E-mail Genérico",
			marketingEmail: "Marketing E-mail",
			accountConfirmationCode: "Confirmação de Conta Utilizador por Código",
			recoverPasswordCode: "Recuperação de Password por Código",
			accountConfirmationLink: "Confirmação de Conta Utilizador por Link",
			recoverPasswordLink: "Recuperação de Password por Link",
			reservationEmail: "E-mail de Confirmação de Reserva",
			staffAccountConfirmation: "Confirmação de Conta de Acesso ao BO",
			staffRecoverPassword: "Recuperação de Password de Acesso ao BO",
			changeEmailCode: "Alteração de E-mail (Código)",
			changeEmailLink: "Alteração de E-mail (Link)"
		},

		businesses: {
			business: "Cliente",
			new: "Novo Cliente",
			header: "Cliente",
			plural: "Clientes",
			info: "Geral",
			deleted: "Eliminar Cliente",
			fullInfo: "Informação do Cliente",
			schedule: "Horário",
			settings: "Definições",
			financial: "Financeiro",
			addressLine1: "Endereço 1",
			addressLine2: "Endereço 2",
			location: "Localização",
			businessInformation: "Informação do Cliente",
			vatNumber: "NIF",
			invoiceName: "Nome do Cliente",
			iban: "IBAN",
			bic: "BIC",
			emptyVat: "O campo NIF é de preenchimento obrigatório.",
			invalidVat: "O NIF introduzido não é válido.",
			invalidName: "O campo nome é de preenchimento obrigatório.",
			invalidOwnerName: "O campo nome do proprietário é de preenchimento obrigatório.",
			invalidOwnerEmail: "O campo e-mail do proprietário é de preenchimento obrigatório.",
			emptyCountry: "O campo país é de preenchimento obrigatório.",
			emptyState: "O campo distrito é de preenchimento obrigatório.",
			ownerPhone: "Telefone do Proprietário",
			emptyOwnerNumber: "O campo telefone do proprietário é de preenchimento obrigatório.",
			approved: "Aprovado",
			approve: "Aprovar",
			added: "O cliente foi criado com sucesso.",
			edited: "O cliente foi editado com sucesso.",
			removed: "O cliente foi apagado com sucesso.",
			categories: "Categorias",
			minPriceRange: "Valor Intervalo Mínimo",
			maxPriceRange: "Valor Intervalo Máximo",
			wallet: "Carteira",
			emptyAddress: "O campo endereço é de preenchimento obrigatório.",
			invalidMaxRange: "O valor de intervalo máximo não pode ser inferior ao valor mínimo.",
			balance: "Saldo",
			allTransactions: "Todas as transações",
			monthBalance: "Saldo Mensal",
			totalAccumulated: "Saldo Acumulado",
			transactions: "Transações",
			receiptValue: "Valor do Recibo",
			netBalance: "Valor Líquido Recebido",
			total: "Total",
			user: "Colaborador",
			contracts: "Contratos",
			typeContract: "Tipo",
			descriptionContract: "Descrição",
			addContract: "Adicionar contrato",
			editContract: "Editar contrato",
			contractAdded: "O contrato foi criado com sucesso.",
			contractEdited: "O contrato foi editado com sucesso.",
			contractRemoved: "O contrato foi apagado com sucesso.",
			startDateRequired: "A data inicial do contrato é um campo de preenchimento obrigatório.",
			endDateRequired: "A data final do contrato é um campo de preenchimento obrigatório.",
			valueRequired: "O número de consultas é um campo de preenchimento obrigatório.",
			datesIntervalInvalid: "O intervalo de datas é inválido."
		},

		contracts: {
			business: "Cliente",
			new: "Novo Contrato",
			type: "Tipo de Contrato",
			parcel: "Parcel",
			food: "Alimentar",
			startDate: "Data de Início",
			endDate: "Date de Fim",
			baseLocation: "Localização Base",
			searchAddress: "Procurar Morada",
			startPostcode: "Início de Intervalo de Código Postal",
			endPostcode: "Fim de Intervalo de Código Postal",
			invalidWorkAreaInterval: "O último intervalo inserido não é válido.",
			workAreas: "Áreas de Trabalho",
			attachments: "Anexos",
			noData: "Ainda não foram adicionados anexos.",
			startDateMandatory: "O campo da data inícial do contrato é de preenchimento obrigatório.",
			endDateMandatory: "O campo da data final do contrato é de preenchimento obrigatório.",
			typeMandatory: "O campo do tipo de contrato é de preenchimento obrigatório.",
			addAttachment: "Adicionar Anexo",
			editAttachment: "Editar Anexo",
			attachment: "Anexo",
			attachmentMandatory: "É necessário selecionar um ficheiro para o anexar.",
			descriptionMandatory: "O campo descrição é de preenchimento obrigatório.",
			document: "Documento",
			horeca: "Horeca",
			baseLocationAddressMandatory: "A morada de localização base é obrigatória.",
			baseLocationAddress: "Morada da Localização Base",
			warehouseId: "ID Armazém"
		},

		contractTypes: {
			contractUpdated: "O tipo de contrato foi editado com sucesso."
		},

		orders: {
			registered: "Registadas",
			addressConfirmation: "Validação de Moradas",
			picking: "Seleção",
			packing: "Embalagem",
			loading: "Loading",
			on_transit: "Em trânsito",
			delivered: "Entregues",
			notDelivered: "Não Entregues",
			orderDelivered: "Entregue",
			orderNotDelivered: "Não Entregue",
			orderCancelled: "Cancelada",
			cancelled: "Canceladas",
			refunded: "Devoluções",
			selectBusiness: "Selecione um cliente",
			selectContract: "Selecione um contrato",
			orderId: "ID Encomenda",
			deliveryDate: "Data Prevista",
			deliveredDate: "Data de Entrega",
			deliveredTime: "Hora de Entrega",
			clientName: "Nome Cliente",
			orderAddress: "Morada",
			userAddress: "Morada de Envio",
			deliverySlot: "Slot de Entrega",
			latitude: "Latitude",
			longitude: "Longitude",
			validationDateError: "Data: A encomenda não possui data de entrega.",
			validationPostCodeError:
				"Código Postal: A encomenda não possui código postal ou não é válido.",
			validationAddressError:
				"Morada: A encomenda não possui uma morada de entrega ou necessita de validação.",
			validationCoordinatesError:
				"Coordenadas: Não foi possível adquirir as coordenadas da morada.",
			validationPhoneError: "Telefone: A encomenda não possui um número de telefone.",
			definePicking: "Avançar para Seleção",
			refresh: "Recarregar",
			edited: "A encomenda foi editada com sucesso.",
			editedPlural: "As encomendas foram editadas com sucesso.",
			imported: "Encomendas importadas com sucesso.",
			download: "Download Template",
			uploadFile: "Clique ou arraste o ficheiro para aqui",
			import: "Importar",
			upload: "Importar Encomendas",
			pleaseSelectBoth: "Por favor, selecione um cliente e um contrato",
			pleaseSelectBusiness: "Por favor, selecione um cliente",
			pleaseSelectContract: "Por favor, selecione um contrato",
			pleaseSelectDate: "Por favor, selecione uma data",
			orderDate: "Data de Encomenda",
			slot: "Slot de Entrega",
			status: "Estado",
			formattedOrderAddress: "Morada de Entrega Formatada",
			clientAddress: "Morada do Cliente",
			newAddress: "Nova Morada",
			importedErrors: "Encomendas não Importadas (Erros)",
			missingOrderId: "Uma encomenda com este ID já foi importada",
			importedWithErrors:
				"Encomendas importadas com sucesso. Algumas encomendas não foram importadas (ver lista de erros).",
			serviceType: "Tipo de Serviço",
			totalWeight: "Peso Total",
			totalVolume: "Volume Total",
			itemsQuantity: "N. Total Items",
			finalAddress: "Morada Final",
			noErrors: "A encomenda não tem qualquer erro registado.",
			orderErrors: "Erros",
			coordinates: "Coordenadas",
			defineAddress: "Definir Morada de Envio",
			normal: "Normal",
			phoneMandatory: "O campo telefone é de preenchimento obrigatório.",
			addressMandatory: "É necessário fornecer uma morada de entrega.",
			selectShippingAddress: "Selecione uma morada de envio ou crie uma nova.",
			bagId: "ID Saco",
			totalBoxes: "N.º Caixas",
			packaging: "Embalagem",
			packagingType: "Tipo de Embalagem",
			bag: "Saco",
			box: "Caixa",
			boxId: "ID Caixa",
			addBox: "Adicionar Caixa",
			weight: "Peso",
			fillBagFields: "Todos os campos são de preenchimento obrigatório.",
			fillOneBox: "É necessário criar, pelo menos, uma caixa.",
			boxMissingFields: "Existe(m) caixa(s) com campos por preencher.",
			createBox: "Criar Caixas",
			moveToPacking: "Avançar para Embalagem",
			routes: "Rotas",
			routeName: "Nome da Rota",
			routeDate: "Data da Rota",
			gradeId: "ID Grade",
			loadingOrder: "Ordem de Carregamento",
			evalyzeId: "ID Evalyze",
			routeInfo: "Dados da Rota",
			routeBoxes: "Caixas da Rota",
			position: "Posição Grade",
			gradeIdMandatory: "O ID da grade é um campo de preenchimento obrigatório.",
			vanMandatory: "A selecção da Matrícula é obrigatória.",
			routeEdited: "A rota foi editada com sucesso.",
			published: "Publicada",
			driver: "Condutor",
			missingMandatoryFields: "Campos obrigatórios em falta",
			addressNumber: "N.º",
			addressFloor: "Andar",
			invalidPostCode: "O código postal não é válido (XXXX-XXX) ou não foi inserido",
			invalidDateFormat: "O formato da data não é válido (YYYY-MM-DD)",
			invalidSlot: "O formato da slot não é válido (HH:MM)",
			savePacking: "Concluir Packing",
			aggregateBags: "Agregar Sacos",
			aggregation: "Agregação",
			grid: "Grade",
			line: "Linha",
			column: "Coluna",
			depth: "Profundidade",
			route: "Rota",
			readyToVan: "Preparar Van",
			readyToLoad: "Preparar Carga",
			moveToLoad: "Avançar para Preparar Carga",
			moveToReadyToVan: "Avançar para Preparar Van",
			moveToDispatching: "Avançar para Dispatching",
			moveToOnTransit: "Avançar para Trânsito",
			readyToDeliver: "Pronto para entrega",
			readyToDispatch: "Pronto para dispatching",
			vehicle: "Veículo",
			warehouse: "Armazém",
			stopType: "Tipo de Paragem",
			address: "Morada",
			archiveMessage: "Tem a certeza de que pretende arquivar este item?",
			archive: "Arquivar",
			outOfFormatBoxes: "Caixas fora do formato",
			outOfRouteBoxes: "Caixas fora de rota",
			progress: "Progresso",
			selectDriver: "Selecionar Motorista",
			selectGrid: "Selecionar Grade",
			selectVehicle: "Selecionar Veículo",
			selectOrderStatus: "Estado da Encomenda",
			complaints: "Reclamações",
			billing: "Faturação",
			orderDeliveryInfo: "Informação de Entrega",
			notes: "Notas",
			images: "Imagens",
			nextStatus: "Próximo Estado",
			previousStatus: "Estado Anterior",
			staff: "Funcionário",
			orderLogs: "Logs da Encomenda",
			operationsBlock: "Bloco de Operações",
			arrivalTime: "Tempo de Chegada",
			departureTime: "Tempo de Saída",
			estimatedTimeArrival: "Tempo estimado de chegada",
			estimatedTimeDeparture: "Tempo estimado de saída",
			boxes: "Caixas",
			noBoxes: "A encomenda ainda não tem qualquer caixa associada",
			marketPlaceCode: "Código Marketplace",
			clientId: "ID Cliente",
			notDeliveredReason: "Motivo de não entrega",
			orderNotInPacking: `Encomenda fora do estado "Em Embalagem"`,
			orderNotFound: "A encomenda não existe",
			pile: "Pilha",
			lot: "Lote",
			route4meSyncMessage:
				"Esta ação irá reescrever todas as rotas deste contrato no dia selecionado com dados do route4me, pretende avançar?",
			syncRoute4me: "Sincronizar do route4me",
			synced: "Rota sincronizada com sucesso"
		}
	},

	/* cSpell.language:en */
	en: {
		// Generic
		generic: {
			askToSave: "Deseja guardar as alterações antes de sair da página?",
			edit: "Edit",
			done: "Done",
			accept: "Accept",
			cancel: "Cancel",
			remove: "Remove",
			forget: "Forget User",
			delete: "Delete",
			success: "Success",
			active: "Active",
			expand: "Expand",
			toggle: "Toggle",
			save: "Save",
			yes: "Yes",
			no: "No",
			apply: "Apply",
			item: "Item",
			items: "Items",
			submit: "Submit",
			continue: "Continue",
			confirm: "Confirm",
			finish: "Finish",
			back: "Back",
			oops: "Oops",
			error: "Error",
			retry: "Retry",
			reset: "Reset",
			search: "Search",
			noResults: "No Results",
			clickHereTo: "Click here to {0}",
			requiredField: "{0} is a required field",
			duplicateEmail: "This email is already taken. Please use another email.",
			emptyList: "No {0} in List",
			leavePage: "Leave Page?",
			leave: "Leave",
			stay: "Stay",
			open: "Open",
			close: "Close",
			change: "Change",
			free: "Free",
			send: "Send",
			selected: "Selected",
			selecteds: "Selected",
			swalEditTitle: "Cancel Edit?",
			swalEditDescription: "Cancelling edit will erase all the unsaved information!",
			leaveDescription: "Leaving this page will erase all the unsaved information!",
			swalDeleteTitle: "Delete {0}?",
			swalDeleteDescription: "By leaving this page, you'll erase all the {0} on your list",
			genericSelect: "Select {0}",
			select: "Select",
			addGeneric: "Add {0}",
			moreOptions: "More Options",
			all: "All",
			enable: "Enable",
			newF: "New",
			newM: "New",
			addNewF: "Add new {0}",
			addNewM: "Add new {0}",
			default: "Default",
			editGeneric: "Edit {0}",
			infinite: "Infinite",
			details: "Details",
			noItemsSelected: "No Items Selected",
			clearList: "Clear List",
			loading: "Loading...",
			view: "View",
			actions: "Actions",
			invalidUrl: "Invalid URL. Please remove HTTP prefix.",
			shop: "Shop",
			noEntries: "No Entries",
			chatWalktroughText1:
				"We've added several FAQs that will help you know and understand how the Shop2Grow product works. These FAQs are available from the chat icon at the bottom right of the screen.",
			chatWalktroughText2:
				"We are also available through chat to help you the best way on to setting up your store.",
			skip: "Skip",
			walkTrough: "walkthrough",
			clone: "Clone",
			download: "Download",
			upload: "Upload",
			template: "Download Template",
			export: "Export",
			import: "Import",
			defaultItem: "an item",
			defaultItemRecipient: "this {0}",
			demo: "Demo",
			previewOnly: "Preview Only",
			selectPaymentMethod: "Please select a payment method before updating your subscription.",
			basic: "Basic",
			advanced: "Advanced",
			editMode: "Edit Mode",
			print: "Print",
			code: "Code",
			filters: " Filters",
			changeImage: "Click here to add an image file or drag it into this box.",
			changeFile: "Click here to add a file or drag it into this box.",
			userImage: `This user don't have any image`,
			changeVideo: "Click here to add a video file or drag it into this box.",
			changeYoutubeVideo: "Insert youtube link",
			changeAudio: "Click here to add an audio file or drag it into this box.",
			everyF: "All",
			everyM: "All",
			generalInformation: "General Information",
			sync: "Sync"
		},

		fields: {
			name: "Name",
			externalName: "External Name",
			address: "Address",
			addresses: "Addresses",
			country: "Country",
			state: "State",
			city: "City",
			zipCode: "Zip Code",
			description: "Description",
			phone: "Phone",
			phoneNumber: "Phone Number",
			phoneShort: "Tel.:",
			mobilePhone: "Mobile Number",
			email: "Email",
			seoUrl: "SEO Url",
			tags: "Tags",
			ccNumber: "CC Number",
			billingAddress: "Billing Address",
			shippingAddress: "Shipping Address",
			vat: "VAT",
			password: "Password",
			message: "Message",
			image: "Image",
			images: "Images",
			imgPlaceholder: "Try dropping some images here, or click to select files to upload",
			filesPlaceholder: "Try dropping your file here, or click to select a file",
			subTotal: "Subtotal",
			total: "Total",
			quantity: "Quantity",
			ordered: "Ordered",
			totalQuantity: "Total Quantity",
			discount: "Discount",
			registerDate: "Register Date",
			invalidEmail: "Email is not Valid",
			tax: "Tax",
			addedOn: "Added On",
			startDate: "Start Date",
			endDate: "End Date",
			startHour: "Start Hour",
			endHour: "End Hour",
			filename: "Filename",
			gender: "Gender",
			waitingForResults: "Waiting for Name or SKU...",
			link: "Link",
			date: "Date",
			otherRegions: "Other Zones",
			taxValues: "Tax Values",
			previewLink: "Preview Link",
			clickToUpload: "Click to Upload",
			photo: "Photo",
			shift: "Shift",
			shiftEnd: "Shift End",
			shiftStart: "Shift Start",
			contractTypeName: "Contract Type Name",
			contractTypeUrl: "URL optimizer",
			isActive: "Is Active",

			// Select
			selectCountry: "Select Country",
			selectState: "Select State",
			selectFilters: "Select Filters",
			selectDiscount: "Select Discount",

			countries: "Countries",
			states: "States",

			// Pairs
			valuePair: "{0} / {1}",
			placeHolderPair: "{0} => {1}",

			code: "Abbreviation",
			bccEmail: "Email to receive orders",
			article: "Article",

			logo: "Logo",
			category: "Category",
			weight: "Weight"
		},

		language: {
			header: "Language",
			defaultLanguage: "Default Language",
			languageNotActive: "Save the changes before changing the default language.",
			portuguese: "Portuguese",
			english: "English",
			german: "German",
			french: "French",
			spanish: "Spanish"
		},

		sidebar: {
			dashboard: "Dashboard",
			profile: "Profile",
			information: "Information",
			staff: "Staff",
			settings: "Settings",
			logs: "Logs",
			users: "Users",
			businesses: "Clients",
			orders: "Orders"
		},

		header: {
			account: "Account"
		},

		// Screens
		authentication: {
			welcome: "Welcome",
			header: "Authentication",
			register: "Register",
			login: "Login",
			logout: "Logout",
			signIn: "Sign in",
			confirmAccount: "Confirm Account",
			name: "Name",
			namePlaceholder: "Your name",
			email: "Email",
			emailPlaceholder: "Your email",
			password: "Password",
			passwordPlaceholder: "Your password",
			newPassword: "New password",
			newPasswordPlaceholder: "Enter new password",
			confirmPassword: "Confirm Password",
			confirmPasswordPlaceholder: "Repeat your password",
			forgotPassword: "Forgot your password? Click here.",
			loginFooter: "No account? Register here",
			registerFooter: "Do you have an account? Login here",
			resendCode1: "Have you not received your confirmation code? ",
			resendCode2: "Resend activation code",
			sendEmail: "Send Email",
			recoverPassword: "Recover Password",
			changePassword: "Change Password",
			changeEmail: "Change Email",
			newEmail: "New Email",
			repeatEmail: "Repeat Email",
			newEmailConfirm: "Confirm New Email",
			currentEmail: "Current Email",
			currentPassword: "Current Password",
			emailSent: "Email Sent Successfully",
			repeatPassword: "Repeat Password",
			rememberedPassword: "Did you remember your password?",
			adminPassword: "Admin Password",
			notLoggedIn: "You need to be logged in to access this page",
			noMatchPassword: "Passwords don't match",
			acceptSubtitle: "Please input a password to confirm your account.",
			confirmInvite: "Please type your password to accept invite.",
			goBackToLogin: "Go to the login screen",
			resendInviteEmail: "Resend Invitation Email",
			confirmResendInviteEmail: "Resend an invitation email to {0}?",
			forgetTitle:
				"Are you sure you want to forget the user {0}? This action will leave the user without access to the app and all the user information will be removed.",
			forgetConfirm: "Forget User",
			backoffice: "Backoffice"
		},

		acceptInvite: {
			header: "Accept Invite",
			title: "Hi {0}, {1} has invited you to belong {2}",
			title2: "Hi {0}, {1} has invited you to manage {2}",
			subTitle: "Please choose a password to confirm your account",
			expired: "This invitation link has expired"
		},

		notFound: {
			header: "Not Found"
		},

		importModal: {
			fileName: "Filename",
			size: "Size",
			import: "Import"
		},

		errors: {
			invalidArgs: "Invalid or missing arguments",
			invalidFields: "Invalid or missing fields",
			emailIsInvalid: "Email format is not valid",
			fillEmail: "Please input your email",
			fillPassword: "Please input your password",
			pleaseFillFormCorrectly: "Please fill the form correctly",
			fillField: "This field is mandatory",
			notSupportedFile: "File type not supported",
			nameIsEmpty: "Please fill name field",
			passwordsDontMatch: "Fields 'New password' and 'Confirm password' don't match",
			emailIsEmpty: "Please fill email field",
			fileTooBigorInvalid: "File size is larger than 8MB or file is corrupt",
			unsupportedFile: "File not supported",
			fileNotSupported: "File you tried to upload is not supported.",
			processingError: "Processing Error",
			imageProcessingError: "{images} could not be processed.",
			shiftDateError: "The shift end date has to be a previous date than the shift start one."
		},

		profile: {
			profilePicture: "Profile Picture",
			role: "Role",
			editedSuccessfully: "Profile edited successfully",
			recurrence: "Recurrence",
			recurrenceActive: "Enable Recurrence",
			recurrenceUntil: "Recurrent until",
			recurringEndMissing: "Recurrence end date is mandatory.",
			recurrenceSuccess: "Recurrence settings saved successfully.",
			deleteRecurrence: "Delete Recurrence",
			noThankYou: "No Thank You",
			recurrenceTooltip: "Create recurrence",
			deleteRecurrenceDescription: "Do you want to delete recurrence on {date}?"
		},

		placeholders: {
			name: "Your name",
			email: "your@email.com",
			phoneNumber: "+351265000000",
			language: "Select a language option",
			role: "Select a role",
			currentPassword: "actual password",
			newPassword: "new password",
			confirmPassword: "new password",
			newEmail: "new@email.com",
			page: "page",
			titlePage: "Page Title",
			link: "https://www.google.com/"
		},

		staff: {
			owner: "Owner",
			owners: "Owners",
			admin: "Administrator",
			marketing: "Marketing",
			editedSuccessfully: "User edited successfully",
			passwordChangedSuccessfully: "Password changed successfully",
			emailChangedSuccessfully: "Email changed successfully",
			wrongPassword: "Wrong password",
			emailInUse: "Email in use",
			status: "Status",
			confirmed: "Confirmed",
			pending: "Pending",
			createStaff: "Create User",
			createdSuccessfully: "User has been created successfully.",
			sysAdmin: "System Admin",
			addStaff: "Add User",
			editStaff: "Edit User",
			addOwner: "Add an Owner",
			editOwner: "Edit Owner",
			user: "User",
			archiveStaff: "Archive User",
			archived: "User has been archived successfully.",
			disableStaff: "Disable User",
			enableStaff: "Enable User",
			disabled: "User disabled successfully.",
			enabled: "User enabled successfully.",
			dispatcher: "Dispatcher",
			manager: "Manager",
			driver: "Driver",
			operator: "Operator",
			supervisor: "Supervisor"
		},

		ranges: {
			today: "Today",
			thisMonth: "This month",
			lastMonth: "Last month",
			thisYear: "This year",
			lastYear: "Last year"
		},

		dashboard: {
			period: "Period",
			active: "Active",
			homepage: "Homepage",
			inStock: "In Stock",
			daily: "Dialy",
			monthly: "Monthly",
			newUsers: "New Users",
			newUsersContent: "New Users Content",
			uses: "Uses",
			androidUse: "Android Usage",
			androidUseContent: "Android Usage Content",
			iOSUse: "iOS Usage",
			iOSUseContent: "iOS Usage Content",
			webUse: "Web Usage",
			webUseContent: "Web Usage Content",
			contract: "Contract",
			sosButton: "Call Button",
			chatButton: "Chat Button",
			usage: "Users",
			videoAppointments: "Psychologic video-consultations",
			hotline: "Psychological Hotline",
			disturbances: "Diagnosed Disorders",
			contractDetails: "Contract Details",
			purchased: "Purchased",
			consumed: "Used",
			balance: "Balance",
			scheduled: "Scheduled Consultations",
			finished: "Finished Consultations",
			details: "See Detail",
			clicked: "Num. Clicks",
			usageApp: "App Usage",
			calls: "Calls",
			chats: "Chats",
			records: "Interventions Records",
			noData: "No data for the selected period.",
			selectPsychologist: "Select Psychologist",
			question1: "De forma geral, está satisfeito(a) com o serviço prestado?",
			question2: "Em que medida gostou do(a) psicólogo(a) que o(a) atendeu?",
			question3: "O(a) psicólogo(a) foi pontual no seu atendimento?",
			question4: "Sente-se melhor depois da intervenção psicológica realizada?",
			question5: "No seu entender, o número de consultas está a foi adequado?",
			question6: "Em caso de necessidade, voltaria a utilizar o serviço?",
			question7: "Quanto recomendaria este serviço a um colega?",
			question8: "Considera que esta intervenção ajudou que se sentisse mais motivado?",
			question9:
				"Com esta intervenção, tornou-se mais fácil gerir o equilíbrio entre a vida familiar e o trabalho?",
			question10:
				"Considera importante manter o serviço de apoio psicológico na sua organização?",
			notes: "Caso queira deixar alguma sugestão, por favor, escreva abaixo",
			reviewHelp:
				'De {0} a {1}, em que {2} é "{3}" e {4} corresponde a "{5}", por favor, classifique as seguintes afirmações relativas às vídeo-consultas de Psicologia realizadas com os psicólogos da TEAM 24.',
			notReally: "muito pouco",
			little: "pouco",
			few: "moderadamente",
			many: "muito",
			alot: "extremamente"
		},

		settings: {
			pages: "Pages",
			pagesDescription:
				"In this area you will be able to change the content of Terms & Condition and Privacy Policy",
			emailTemplates: "E-mail Settings",
			emailTemplatesDescription:
				"Setup email templated, used to sent emails to users, and also used to manage staff invitation",
			vehicles: "Vehicles",
			vehiclesDescription: "Register your fleet of vehicles and its caracteristics",
			addVehicle: "Add vehicle",
			editVehicle: "Edit vehicle",
			added: "Vehicle has been created successfully.",
			edited: "Vehicle has been edited successfully.",
			deleted: "Vehicle has been deleted successfully.",
			licensePlate: "License Plate",
			licensePlateRequired: "License Plate is a mandatory field.",
			nameRequired: "Name is a mandatory field.",
			typeRequired: "Type is a mandatory field.",
			capacityRequired: "Capacity is a mandatory field.",
			vehiclePlate: "Vehicle License Plate",
			vehicleType: "Vehicle Type",
			contractType: "Contract Type",
			capacity: "Capacity",
			van: "Van",
			bike: "Bike",
			boxes: "Boxes",
			grids: "Grid",
			gridsDescription: "Register a grid to fill in routes",
			shifts: "Shift",
			shiftsDescription: "Register a shift to use in the orders",
			addGrid: "Add Grid",
			editGrid: "Edit Grid",
			addShift: "Add Shift",
			editShift: "Edit Shift",
			contractTypes: "Contract Types",
			contractTypesDescription: "Register a contract type.",
			food: "Food",
			parcel: "Parcel",
			pharmacy: "Pharmacy",
			contractUrl: "Optimizer Url",
			editContractType: "Edit Contract Type",
			addContractType: "Add Contract Type",
			uploadMasterData: "Upload Master Data",
			uploadMasterDataSubtitle:
				"Upload the Master Data file (pharmacy contracts), to import clients to the system",
			userAlreadyExists: "There's already a client with that id",
			importedWithErrors:
				"Master Data file has been imported successfully but there are some clients that were not imported (see errors list).",
			imported: "Master Data file has been imported successfully."
		},

		pages: {
			single: "Page",
			title: "Title",
			content: "Content",
			new: "New Page",
			isLink: "Redirect"
		},

		templates: {
			single: "Template"
		},

		emails: {
			single: "E-mail",
			tags: "Tags",
			preview: "Preview",
			subject: "Subject",
			cc: "CC",
			templates: "E-mail Templates",
			invite: "Staff Invite",
			edited: "E-mail template saved successfully.",
			plainText: "Plain text"
		},

		serverErrors: {
			title: "Internal Server Error",
			subTitle: "It's not you, it's us!",
			refresh: "Go Back to Previous Page",
			backToDashboard: "Go to Dashboard",
			wentWrong: "Something went wrong."
		},

		logs: {
			dateRange: "Date Range",
			userId: "User Id",
			url: "Url",
			token: "Token",
			codes: "Codes",
			methods: "Methods",
			sources: "Sources",
			types: "Types"
		},

		emailTemplates: {
			generalEmail: "Generic E-mail",
			marketingEmail: "Marketing E-mail",
			accountConfirmationCode: "User Account Confirmation by Code",
			recoverPasswordCode: "User Password Recovery by Code",
			accountConfirmationLink: "User Account Confirmation by Link",
			recoverPasswordLink: "User Password Recovery by Link",
			reservationEmail: "User Reservation E-mail",
			staffAccountConfirmation: "Staff Account Confirmation for BO",
			staffRecoverPassword: "Staff Recover Password for BO",
			changeEmailCode: "Change Email (Code)",
			changeEmailLink: "Change Email (Link)"
		},

		businesses: {
			business: "Client",
			new: "New Client",
			header: "Business",
			plural: "Businesses",
			info: "General",
			deleted: "Delete Client",
			fullInfo: "Business Information",
			schedule: "Schedule",
			settings: "Settings",
			financial: "Financial",
			addressLine1: "Address Line 1",
			addressLine2: "Address Line 2",
			location: "Location",
			businessInformation: "Business Information",
			vatNumber: "VAT Number",
			invoiceName: "Invoice Name",
			iban: "IBAN",
			bic: "BIC",
			emptyVat: "Vat number field can not be empty.",
			invalidVat: "Vat number is not valid.",
			invalidName: "Name is a mandatory field.",
			invalidOwnerName: "Owner name is a mandatory field.",
			invalidOwnerEmail: "Owner e-mail is either empty or invalid.",
			emptyCountry: "Country is a mandatory field.",
			emptyState: "State is a mandatory field.",
			ownerPhone: "Owner Phone",
			emptyOwnerNumber: "Owner phone number is a mandatory field.",
			approved: "Approved",
			approve: "Approve",
			added: "Business has been created successfully.",
			edited: "Business has been updated successfully.",
			removed: "Business has been deleted successfully.",
			categories: "Categories",
			minPriceRange: "Min. Price Range",
			maxPriceRange: "Max. Price Range",
			wallet: "Wallet",
			emptyAddress: "Address is a mandatory field.",
			invalidMaxRange: "Max. price range needs to be higher than min price range.",
			balance: "Balance",
			allTransactions: "All transactions",
			monthBalance: "Month Balance",
			totalAccumulated: "Accumulated Balance",
			transactions: "Transactions",
			receiptValue: "Receipt Amount",
			netBalance: "Net Balance",
			total: "Total",
			user: "User",
			contracts: "Contracts",
			typeContract: "Type",
			descriptionContract: "Description",
			addContract: "Add contract",
			editContract: "Edit contract",
			contractAdded: "Contract has been created successfully.",
			contractEdited: "Contract has been updated successfully.",
			contractRemoved: "Contract has been deleted successfully.",
			startDateRequired: "Contract start date is a mandatory field.",
			endDateRequired: "Contract end date is a mandatory field.",
			valueRequired: "Contract consultations is a mandatory field.",
			datesIntervalInvalid: "Invalid date interval."
		},

		contracts: {
			business: "Business",
			new: "New Contract",
			type: "Contract Type",
			parcel: "Parcel",
			food: "Food Distribution",
			startDate: "Start Date",
			endDate: "End Date",
			baseLocation: "Base Location",
			searchAddress: "Search Address",
			startPostcode: "Postcode Range Start",
			endPostcode: "Postcode Range End",
			invalidWorkAreaInterval: "Last range added is not valid.",
			workAreas: "Work Areas",
			attachments: "Attachments",
			noData: "No attachments added.",
			startDateMandatory: "Start date is a mandatory field.",
			endDateMandatory: "End date is a mandatory field.",
			typeMandatory: "Contract type is a mandatory field.",
			addAttachment: "Add Attachment",
			editAttachment: "Edit Attachment",
			attachment: "Attachment",
			attachmentMandatory: "You need to add a file first.",
			descriptionMandatory: "Description is a mandatory field.",
			document: "Document",
			horeca: "Horeca",
			baseLocationAddress: "Base Location Address",
			baseLocationAddressMandatory: "The base location address is mandatory.",
			warehouseId: "Warehouse ID"
		},

		contractTypes: {
			contractUpdated: "Contract has been updated successfully."
		},

		orders: {
			registered: "Registered",
			addressConfirmation: "Address Confirmation",
			picking: "Picking",
			packing: "Packing",
			loading: "Loading",
			on_transit: "On Transit",
			delivered: "Delivered",
			notDelivered: "Not Delivered",
			orderDelivered: "Delivered",
			orderNotDelivered: "Not Delivered",
			orderCancelled: "Cancelled",
			cancelled: "Cancelled",
			refunded: "Refunded",
			selectBusiness: "Select a business",
			selectContract: "Select a contract",
			orderId: "Order ID",
			deliveryDate: "Delivery Date",
			deliveredTime: "Delivered Time",
			deliveredDate: "Delivered Date",
			clientName: "Client Name",
			orderAddress: "Address",
			userAddress: "Shipping Address",
			deliverySlot: "Slot",
			latitude: "Latitude",
			longitude: "Longitude",
			validationDateError: "Date: Order has no delivery date.",
			validationPostCodeError: "Post Code: Order has no post code or it's invalid.",
			validationAddressError: "Address: Order has no delivery address or needs to be validated.",
			validationCoordinatesError:
				"Coordinates: It was impossible to retrive address coordinates.",
			validationPhoneError: "Phone: Order has no phone number.",
			definePicking: "Move to Picking",
			refresh: "Reload",
			edited: "Order has been edited successfully.",
			editedPlural: "Orders have been edited successfully.",
			imported: "Order have been imported successfully.",
			download: "Download Template",
			uploadFile: "Click or drag a file into this box.",
			import: "Import",
			upload: "Import Orders",
			pleaseSelectBoth: "Please select a client and a contract",
			pleaseSelectBusiness: "Please select a client",
			pleaseSelectContract: "Please select a contract",
			pleaseSelectDate: "Please select a date",
			orderDate: "Order Date",
			slot: "Delivery Slot",
			status: "Status",
			formattedOrderAddress: "Formatted Order Address",
			clientAddress: "Client Address",
			newAddress: "New Address",
			importedErrors: "Unimported Orders (Errors)",
			missingOrderId: "This order was already imported",
			importedWithErrors:
				"Orders have been imported successfully but there are some that were not imported (see errors list).",
			serviceType: "Service Type",
			totalWeight: "Total Weight",
			totalVolume: "Total Volume",
			itemsQuantity: "Total No. Items",
			finalAddress: "Shipping Address",
			noErrors: "This order has no errors to display.",
			orderErrors: "Errors",
			coordinates: "Coordinates",
			defineAddress: "Define Shipping Address",
			normal: "Normal",
			phoneMandatory: "Phone is a mandatory field.",
			addressMandatory: "Shipping address is a mandatory field.",
			selectShippingAddress: "Please select a shipping address or create one.",
			bagId: "Bag ID",
			totalBoxes: "Num. Boxes",
			packaging: "Packaging",
			packagingType: "Packaging Type",
			bag: "Bag",
			box: "Box",
			boxId: "Box ID",
			addBox: "Add New Box",
			weight: "Weight",
			fillBagFields: "All bag fields are mandatory.",
			fillOneBox: "You need to create at least one box.",
			boxMissingFields: "There are at least one box with missing fields.",
			createBox: "Create Boxes",
			moveToPacking: "Move to Packing",
			routes: "Routes",
			routeName: "Route Name",
			routeDate: "Route Date",
			gradeId: "Cargo Shelf ID",
			loadingOrder: "Loading Order",
			evalyzeId: "Evalyze ID",
			routeInfo: "Route Data",
			routeBoxes: "Route Boxes",
			position: "Cargo Shelf Position",
			gradeIdMandatory: "Shelf ID is a mandatory field.",
			vanMandatory: "You need to select a license plate.",
			routeEdited: "Route has been edited successfully.",
			published: "Published",
			driver: "Driver",
			missingMandatoryFields: "Missing mandatory fields",
			addressNumber: "No.",
			addressFloor: "Floor",
			invalidPostCode: "Missing or invalid post code (XXXX-XXX)",
			invalidDateFormat: "Invalid date format (YYYY-MM-DD)",
			invalidSlot: "Invalid From/To field (HH:MM)",
			savePacking: "Save Packing",
			aggregateBags: "Aggregate Bags",
			aggregation: "Aggregation",
			grid: "Grid",
			line: "Line",
			column: "Column",
			depth: "Depth",
			route: "Route",
			readyToVan: "Ready To Van",
			readyToLoad: "Ready to load",
			dispatching: "Dispatching",
			moveToReadyToVan: "Move to Ready To Van",
			moveToDispatching: "Move to Dispatching",
			moveToOnTransit: "Move to On Transit",
			readyToDeliver: "Ready to deliver",
			readyToDispatch: "Ready to dispatch",
			vehicle: "Vehicle",
			warehouse: "Warehouse",
			stopType: "Stop Type",
			address: "Address",
			archiveMessage: "Are you sure you want to archive this?",
			archive: "Archive",
			outOfFormatBoxes: "Out of format boxes",
			outOfRouteBoxes: "Out of route boxes",
			progress: "Progress",
			selectDriver: "Select Driver",
			selectGrid: "Select Grid",
			selectVehicle: "Select Vehicle",
			selectOrderStatus: "Select Order Status",
			complaints: "Complaints",
			billing: "Billing",
			orderDeliveryInfo: "Delivery Info",
			notes: "Notes",
			images: "Images",
			nextStatus: "Next Status",
			previousStatus: "Previous Status",
			staff: "Staff",
			orderLogs: "Order Logs",
			operationsBlock: "Operations Block",
			arrivalTime: "Arrival Time",
			departureTime: "Departure Time",
			estimatedTimeArrival: "Estimated Time Arrival",
			estimatedTimeDeparture: "Estimated Time Departure",
			boxes: "Boxes",
			noBoxes: "This order has no boxes yet",
			marketPlaceCode: "Marketplace code",
			clientId: "Client ID",
			notDeliveredReason: "Not delivered reason",
			orderNotInPacking: `Order not in "Packing" status`,
			orderNotFound: "Order not Found",
			pile: "Pile",
			lot: "Lot",
			route4meSyncMessage:
				"This actions will rewrite all routes from this contract on selected day using route4me, do you wish to continue?",
			syncRoute4me: "Sync from route4me",
			synced: "Route has been synced successfully."
		}
	}
})
